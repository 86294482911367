<template>
  <div class="row mx-0 justify-content-center text-center p-3 position-relative">
    <div class="col-12 green-text-dark font12 bold mb-1">
      DONATING AS:
    </div>
    <div class="col-12 green-text-dark">
      {{theDonorDetails}}
    </div>
    <div class="onhover border_radius_top" :class="{show: isHover1 === true }" @mouseover="isHover1 = true" @mouseleave="isHover1 = false">
      <div class="row m-0 h-100 justify-content-center align-items-center">
        <div class="col-auto">
          <Button color="darkgrey" size="small" btnText="EDIT" icon="arrow" @buttonClicked="$emit('goToDonorPage')">
            <IconEdit size="size16" />
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Review Donation Donor Summary',
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue'))
  },
  emits: ['goToDonorPage'],
  data () {
    return {
      isHover1: false
    }
  },
  computed: {
    ...mapGetters([
      'donateAs'
    ]),
    donatingType () {
      if (this.donateAs) {
        if (this.donateAs.groupID) {
          return 'group'
        } else if (this.donateAs.organisationID) {
          return 'organisation'
        } else {
          return 'individual'
        }
      } else {
        return 'individual'
      }
    },
    theDonorDetails () {
      let displayName = null
      if (this.donateAs) {
        if (this.donatingType === 'organisation') {
          displayName = this.donateAs.registeredName ? this.donateAs.registeredName : this.donateAs.firstName + ' ' + this.donateAs.lastName
        } else {
          displayName = this.donateAs.firstName + ' ' + this.donateAs.lastName
        }
      } else if (!this.donateAs && this.user) {
        displayName = this.user.firstName + ' ' + this.user.lastName
      }
      return displayName
    }
  }
}
</script>
<style scoped>
.onhover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0;
}
.onhover.show {
  opacity: 1;
}
.border_radius_top {
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
}
</style>
